<template>
    <div class="step-content">
        <div class="step-content-header">
            <span class="big">计划设置</span>
        </div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="94px" class="demo-ruleForm" :hide-required-asterisk="true" >
            <el-form-item label="计划名称" prop="title">
                <el-input v-model="ruleForm.title" style="width: 490px" maxlength="30" placeholder="请输入1~30个字"></el-input>
            </el-form-item>
            <el-form-item label="计费方式" prop="CPM">
                <el-popover placement="top-start" width="200" trigger="click" content="CPM是指千次展现计费，依据展现次数进行收费，适合品牌曝光和活动推广。">
                    <i slot="reference" class="iconfont prompt-icon">&#xe61a;</i>
                </el-popover>
                <el-radio-group v-model="ruleForm.CPM" class="radioGroup-red">
                    <el-radio label="CPM"></el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="每日预算" prop="dailyBudget">
                <el-popover placement="top-start" width="200" trigger="click" content="不能低于当前已花费的金额。">
                    <i slot="reference" class="iconfont prompt-icon">&#xe61a;</i>
                </el-popover>
                <el-radio-group v-model="ruleForm.dailyBudget" class="radioGroup-red" @change="toggleDailyBudget">
                    <el-radio :label="0"><span :class="{'isclick':!ruleForm.dailyBudget}">不限预算</span></el-radio>
                    <el-radio :label="1"  @click.native="openCustomBudget">
                        <span  :class="{'isclick': ruleForm.dailyBudget }">自定义预算</span>
                    </el-radio>
                </el-radio-group>
                <span v-if="Number(ruleForm.budget)" style="margin-left: 20px;color: #989898; font-size: 14px">统一日预算（￥）：{{ruleForm.budget}}元</span>
            </el-form-item>
            <el-form-item label="时段设置" prop="time_frame_data">
                <el-popover placement="top-start" width="200" trigger="click" content="系统只会在您设置的时间段内展示广告，其余时间不会展示。">
                    <i slot="reference" class="iconfont prompt-icon">&#xe61a;</i>
                </el-popover>
                全日程展示广告<span style="color: #1E63F1; margin-left: 10px; cursor: pointer" @click="openCustomTime">自定义</span>
            </el-form-item>
            <el-form-item label="投放策略" prop="strategy_type">
                <el-popover placement="top-start" width="200" trigger="click" content="匀速投放：时间段内的广告曝光趋于平滑和均匀；尽快投放：预算尽快消耗。">
                    <i slot="reference" class="iconfont prompt-icon">&#xe61a;</i>
                </el-popover>
                <el-radio-group v-model="ruleForm.strategy_type" class="radioGroup-red">
                    <el-radio :label="1">匀速投放</el-radio>
                    <el-radio :label="2">尽快投放</el-radio>
                </el-radio-group>
                <span class="prompt-text" v-if="ruleForm.strategy_type === 1 && ruleForm.budget === void 0">开启匀速投放必须设置每日预算，请填写每日预算或者关闭匀速投放功能！</span>
            </el-form-item>
            <el-form-item style="margin-left: 94px; margin-top: 50px">
                <el-button class="btn-w-140 btn-red-line" @click="goBack">取消</el-button>
                <el-button class="btn-w-140 btn-red" @click="toNext('ruleForm')">保存并继续</el-button>
            </el-form-item>
        </el-form>

<!--        自定义预算-->
        <CustomBudgetDrawer :ruleForm.sync="ruleForm" ref="customBudgetDrawer" />

<!--        时段设置-->
        <CustomTimeDrawer :ruleForm.sync="ruleForm" ref="customTimeDrawer" />
    </div>
</template>

<script>
    import { StuOpProInfo, StuOpProGetBalance } from '@/utils/apis.js'
    import CustomBudgetDrawer from '@/components/student/exam/infographicPromotion/CustomBudgetDrawer'
    import CustomTimeDrawer from '@/components/student/exam/infographicPromotion/CustomTimeDrawer'
    export default {
        components: {
            CustomBudgetDrawer,
            CustomTimeDrawer,
        },
        data() {
            return {
                ruleForm: {
                    title: '',
                    CPM: 'CPM',
                    dailyBudget: 0, // 每日预算单选 0:不限预算 1：自定义预算
                    budget: void 0, // 预算值
                    show_type: 1, // 时间段设置 1、整天展示 2自定义展示
                    // 时间段设置
                    time_frame_data: [
                        {
                            index: 1, // 时间 1 ：0-1 、 2：1-2、3：2-3
                            type: 1, // 0：不投放 1：无折扣 2：自定义
                            percent: 100, // 比例
                            timezone : '00:00-01:00',
                        },
                        {
                            index: 2,
                            type: 1,
                            percent: 100,
                            timezone : '01:00-02:00',
                        },
                        {
                            index: 3,
                            type: 1,
                            percent: 100,
                            timezone : '02:00-03:00',
                        },
                        {
                            index: 4,
                            type: 1,
                            percent: 100,
                            timezone : '03:00-04:00',
                        },
                        {
                            index: 5,
                            type: 1,
                            percent: 100,
                            timezone : '04:00-05:00',
                        },
                        {
                            index: 6,
                            type: 1,
                            percent: 100,
                            timezone: '05:00-06:00',
                        },
                        {
                            index: 7,
                            type: 1,
                            percent: 100,
                            timezone: '06:00-07:00',
                        },
                        {
                            index: 8,
                            type: 1,
                            percent: 100,
                            timezone: '07:00-08:00',
                        },
                        {
                            index: 9,
                            type: 1,
                            percent: 100,
                            timezone: '08:00-09:00',
                        },
                        {
                            index: 10,
                            type: 1,
                            percent: 100,
                            timezone: '09:00-10:00',
                        },
                        {
                            index: 11,
                            type: 1,
                            percent: 100,
                            timezone: '10:00-11:00',
                        },
                        {
                            index: 12,
                            type: 1,
                            percent: 100,
                            timezone: '11:00-12:00',
                        },
                        {
                            index: 13,
                            type: 1,
                            percent: 100,
                            timezone: '12:00-13:00',
                        },
                        {
                            index: 14,
                            type: 1,
                            percent: 100,
                            timezone: '13:00-14:00',
                        },
                        {
                            index: 15,
                            type: 1,
                            percent: 100,
                            timezone: '14:00-15:00',
                        },
                        {
                            index: 16,
                            type: 1,
                            percent: 100,
                            timezone: '15:00-16:00',
                        },
                        {
                            index: 17,
                            type: 1,
                            percent: 100,
                            timezone: '16:00-17:00',
                        },
                        {
                            index: 18,
                            type: 1,
                            percent: 100,
                            timezone: '17:00-18:00',
                        },
                        {
                            index: 19,
                            type: 1,
                            percent: 100,
                            timezone: '18:00-19:00',
                        },
                        {
                            index: 20,
                            type: 1,
                            percent: 100,
                            timezone: '19:00-20:00',
                        },
                        {
                            index: 21,
                            type: 1,
                            percent: 100,
                            timezone: '20:00-21:00',
                        },
                        {
                            index: 22,
                            type: 1,
                            percent: 100,
                            timezone: '21:00-22:00',
                        },
                        {
                            index: 23,
                            type: 1,
                            percent: 100,
                            timezone: '22:00-23:00',
                        },
                        {
                            index: 24,
                            type: 1,
                            percent: 100,
                            timezone: '23:00-24:00',
                        },
                    ],
                    strategy_type: 1, // 投放策略 1：匀速投放 2尽快投放
                },
                rules: {
                    title: [
                        { required: true, message: '请输入计划名称', trigger: 'blur' },
                        { min: 1, max: 30, message: '长度在 1 到 30 个字符', trigger: 'blur' }
                    ],
                },
                balance: 0, // 推广余额
                initTarget:0,//初始化推广余额,
             
            };
        },
        mounted() {
            if (localStorage.getItem('infoStepTwo')) {
                this.ruleForm = JSON.parse(localStorage.getItem('infoStepTwo'))
            }
            if (this.$route.query.editId) {
                this.getInfo()
            }
            
                this.getBalance()
            
        },
        methods: {
            // 获取推广余额
            getBalance() {
                StuOpProGetBalance(this.$route.query.op_id).then((res) => {
                    this.balance = Number(res.data.balance)
                    this.initTarget=Number(res.data.promote_fee)
                }).catch((err) => {})
            },
            // 获取编辑信息
            getInfo() {
                StuOpProInfo(this.$route.query.editId).then((res) => {
                    this.ruleForm = {
                        title: res.data.title,
                        CPM: 'CPM',
                        // budget: res.data.budget,
                        budget: Number(res.data.budget) ? Number(res.data.budget) : void 0,
                        show_type: res.data.show_type,
                        time_frame_data: res.data.time_frame_data,
                        strategy_type: res.data.strategy_type,
                    }
                  
                    if (Number(res.data.budget) === 0) {
                        this.$set(this.ruleForm, 'dailyBudget', 0)
                    } else {
                        this.$set(this.ruleForm, 'dailyBudget', 1)
                    }
                })
            },
            // 切换每日预算
            toggleDailyBudget(val) {
              
                if (val === 0) {
                    this.ruleForm.budget = void 0
                }
            },
            // 打开自定义预算
            openCustomBudget() {
                this.$refs.customBudgetDrawer.customBudgetDrawer = true
       
                
            },
            // 打开自定义时段设置
            openCustomTime() {
                this.$refs.customTimeDrawer.customTime = true
            },
            // 返回
            goBack() {
                if (this.$route.query.editId) {
                    this.$router.push({
                        path: '/student/infographicPromotion/stepOne',
                        query: {
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id,
                            editId: this.$route.query.editId
                        }
                    })
                } else {
                    this.$router.push({
                        path: '/student/infographicPromotion/stepOne',
                        query: {
                            op_id: this.$route.query.op_id,
                            course_id: this.$route.query.course_id,
                        }
                    })
                }

            },
            // 下一步
            toNext(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        if (this.ruleForm.strategy_type === 1 && this.ruleForm.budget === void 0) {
                            return this.$message.warning('请设置每日预算')
                        }
                        if (this.ruleForm.budget && this.ruleForm.budget > this.initTarget) {
                            return this.$message.warning('自定义预算不得大于推广余额')
                        }
                        localStorage.setItem('infoStepTwo', JSON.stringify(this.ruleForm))
                        if(this.$route.query.editId){
                            this.$router.push({
                                path: '/student/infographicPromotion/stepThree',
                                query: {
                                    op_id: this.$route.query.op_id,
                                    course_id: this.$route.query.course_id,
                                    editId: this.$route.query.editId
                                }
                            })
                        } else {
                            this.$router.push({
                                path: '/student/infographicPromotion/stepThree',
                                query: {
                                    op_id: this.$route.query.op_id,
                                    course_id: this.$route.query.course_id,
                                }
                            })
                        }
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
        }
    }
</script>

<style scoped lang="scss">
    .step-content {
        background: #fff;
        padding: 20px 30px;
        height: calc(100vh - 226px);
    }
    .step-content-header {
        font-weight: 500;
        .big {
            font-size: 28px;
        }
    }
    .demo-ruleForm {
        margin: 50px 0 0 40px;
        ::v-deep {
            .el-form-item__label {
                color: #333;
                font-size: 18px;
            }
            .el-form-item__content {
                font-size: 18px;
            }
        }
    }
    .prompt-icon {
        color: #D7D7D7;
        cursor: pointer;
        margin-right: 20px;
    }
    .prompt-text {
        margin-left: 30px;
        color: #FD4446;
        font-size: 18px;
    }

    ::v-deep .radioGroup-red .el-radio .el-radio__input.is-checked + .el-radio__label{
        color: black;
    }
    ::v-deep .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner{
        box-shadow: none;
    }
    .isclick{
        color: #1E63F1
    }
</style>