<template>
    <el-drawer :wrapperClosable="false" size="50%" title="自定义预算" :with-header="false"
               @closed="closeDrawer" @opened="openDrawer"
               :visible.sync="customBudgetDrawer" direction="rtl" custom-class="custom-drawer">
        <i @click="customBudgetDrawer = false" class="btn-close el-icon-close"></i>
        <div class="custom-budget-content">
            <div class="title">自定义预算</div>
            <div class="item">所有日预算修改后，立即生效。</div>
            <div class="item">统一日预算：从当日起，至未来所有提案数内均执行该预算。</div>
            <div class="item">您可以编辑此次的日预算，输入的范围为：50-9999999，只能输入整数； 若您希望日预算为不限，则可置空不填，即为不限。</div>
            <div class="item">请注意各单元的目标消耗总和不要超过计划日预算，否则会影响消耗优化的功能效果</div>
            <div class="operate-box">
                <span>统一日预算（￥）</span>
                <el-input-number class="number-input" :value="ruleForm.budget" :precision="0" @change="changeBudget"
                                 :controls="false" :min="50" :max="9999999" style="width: 380px; margin-right: 20px"></el-input-number>
                <el-button class="btn-red-line" @click="sureCustomBudget">确定</el-button>
            </div>
        </div>
    </el-drawer>
</template>

<script>
    import { StuOpProGetBalance, StuOpProEditPromote } from '@/utils/apis.js'
    export default {
        props: {
            ruleForm: {
                type: Object,
                default:() => {}
            },
            pageType: {
                type: String,
                default:() => ''
            },
         
        },
        data() {
            return {
                customBudgetDrawer: false,
                balance: null,
                initTarget:null  
            }
        },
        mounted() {

        },
        methods: {
            // 关闭抽屉
            closeDrawer() {
                if (!this.ruleForm.budget) {
                    this.$emit('update:ruleForm', {
                        ...this.ruleForm,
                        dailyBudget: 0,
                        budget: void 0,
                    })
                }
            },
            // 打开抽屉
            openDrawer() {
                this.getBalance()
            },
            // 获取推广余额
            getBalance() {
                StuOpProGetBalance(this.$route.query.op_id).then((res) => {
                    this.balance = Number(res.data.balance)
                    this.initTarget=Number(res.data.promote_fee)
                }).catch((err) => {})
            },
            changeBudget(val) {
                this.$emit('update:ruleForm', {
                    ...this.ruleForm,
                    budget: val,
                })
            },
            sureCustomBudget() {
                if (!this.ruleForm.budget) {
                    return this.$message.warning('请输入统计日预算')
                } else if (this.ruleForm.budget > this.initTarget) {
                    return this.$message.warning(`当前展位余额是${this.initTarget}元，统一日预算已超过展位余额`)
                } else {
                    if (this.pageType === 'list') {
                        let params = {
                            id: this.ruleForm.id,
                            budget: this.ruleForm.budget,
                        }
                        StuOpProEditPromote(params).then((res) => {
                            this.$message.success(res.msg)
                            this.$emit('getList')
                        }).catch((err) => {})
                    }
                    this.customBudgetDrawer = false
                }
            }
        }
    }
</script>

<style scoped lang="scss">
    .custom-budget-content {
        padding: 40px 60px;
        .title {
            // font-size: 24px;
            // font-weight: bold;
            // margin-bottom: 40px;
            color: #333;
            font-size: 24px;
            padding-bottom: 20px;
        }
        .item {
            color: #989898;
            font-size: 18px;
            margin-bottom: 20px;
        }
        .operate-box {
            color: #989898;
            font-size: 18px;
            margin-bottom: 20px;
        }
    }
</style>